@font-face {
  font-family: 'icomoon';
  src:  url('./../fonts/locomotive/icomoon.eot?hab7sk');
  src:  url('./../fonts/locomotive/icomoon.eot?hab7sk#iefix') format('embedded-opentype'),
  url('./../fonts/locomotive/icomoon.ttf?hab7sk') format('truetype'),
  url('./../fonts/locomotive/icomoon.woff?hab7sk') format('woff'),
  url('./../fonts/locomotive/icomoon.svg?hab7sk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon_-"], [class*=" icon_-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_-locomotive:before {
  content: "\e900";
}