@import './md/variables';
@mixin transition($prop: all, $time: .5s) {
  -webkit-transition: $prop $time;
  -moz-transition: $prop $time;
  -ms-transition: $prop $time;
  -o-transition: $prop $time;
  transition: $prop $time;
}
@mixin hide{
  opacity: 0;
  visibility: hidden;
}
@mixin show{
  opacity: 1;
  visibility: visible;
}
