@import "frontend/_helpers";
@import "frontend/_varible";
@import "frontend/_mixin";
a.roundedA {
    display: inline-block;
    padding: 10px 30px 10px 15px;
    @include border-radius-web-kit;
    border-radius: 100px;
    position: relative;
    margin: 5px 0px;
    &:hover{
        opacity: 0.7;
    }
    &:after{
        font-family: Fontawesome;
        content: "\f101";
        right: 15px;
    }
}
a.whiteA{
    color: white !important;
    &:hover{
        opacity: 0.5;
    }
}
a.redA, a.redinvertA:hover {
    background-color: $first-color;
    color: white;
    @include box-shadow-webkit(0px 0px 0 5px, rgba(255, 0, 0, 0.4));
}
a.redinvertA {
    background-color: transparent;
    color: $first-color;
    @include box-shadow-webkit(0px 0px 0 2px,$first-color);
}
a.blueA, a.blueinvertA:hover {
    background-color: $second-color;
    color: white;
    @include box-shadow-webkit(0px 0px 0 5px,$second-color);
}
a.blueinvertA {
    background-color: transparent;
    color: $second-color;
    @include box-shadow-webkit(0px 0px 0 5px,$second-color);
}
a.grayA, a.grayinvertA:hover {
    background-color: gray;
    color: white;
    @include box-shadow-webkit(0px 0px 0 5px, rgba(128, 128, 128, 0.4));
}
a.grayinvertA {
    background-color: transparent;
    color: gray;
    @include box-shadow-webkit(0px 0px 0 5px, rgba(128, 128, 128, 1));
}
a.grayA, a.whiteinvertA:hover {
    background-color: white;
    color: gray;
    @include box-shadow-webkit(0px 0px 0 5px, rgba(255, 255, 255, 0.4));
}
a.basicRedA {
    color: $first-color;
    position: relative;
    display: inline-block;
    font-size: 15px;
    &:hover{
        color: $gray-color;
        &:after{
            right: -25px;
            color: $gray-color;
        }
    }
    &:after{
        position: absolute;
        font-family: FontAwesome;
        content: "\f178";
        right: -20px;
        @include transition-web-kit;
        top: 0;
        color: red;
    }
}
a.bgredA {
    display: inline-block;
    background-color: red;
    padding: 15px;
    position: relative;
    color: white;
}
a.bgredA:hover {
    background-color: gray;
    /*+box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);*/
    -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}
a.arrowLeftA {
    display: inline-block;
    color: $third-color;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    &:hover{
        color:$fourth-color
    }
    &:before{
        position: absolute;
        content: "\f101";
        left: 0;
        font-family: Fontawesome;
    }
}
a.whiteinvertA {
    background-color: transparent;
    color: white;
    @include box-shadow-webkit(0px 0px 0 2px, white);
}
a.grayhoverA {
    @include gray-filter;
    @include transition-web-kit(0.5s);
    &:hover{
        filter: none;
        -webkit-filter: grayscale(0%);
    }
}
@each $name, $size in (lgA, $lg-font-size), (mdA, $md-font-size), (smA, $sm-font-size), (xsA, $xs-font-size) {
    a.#{$name} {
        font-size: $size;
    }
}
.boxShadow {
    @include box-shadow-webkit(0px 0px 10px 0px, rgba(0, 0, 0, 0.3));
}
@each $name, $color in (colorWhite, white), (colorGray,gray), (colorBlack, black), (colorRed, $first-color), (colorBlue, $second-color),(colorYellow, $fourth-color){
    .#{$name}{
        color:$color !important;
    }
}
@each $name, $color in (Gray,gray), (Red, $first-color), (Blue, $second-color){
    .colorBg#{$name}{
        background-color:$color;
        color: white;
    }
}
@each $name in (100), (300),(400),(700){
    .font-#{$name}{
        font-weight: $name;
    }
}
@each $name, $color in (facebook, #3B5998), (twitter, #0084B4), (instagram, #E95950),(youtube, #BB0000), (vimeo, #1AB7EA), (google-plus,#A3A3A3){
    .#{$name}A:hover{
        color:$color;
    }
}
@each $name, $color in (Blue, $second-color), (Red, $first-color), (Gray, $gray-color){
    .colorBg#{$name} {
        background: $color !important;
        color:white !important;
    }
}
.centerH {
    position: relative;
    display: table;
}
.centerH-child {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
}
ul.specialUL {
    padding-left: 15px;
    li{
        list-style: none;
        position: relative;
        padding-left: 20px;
        margin-bottom: 10px;
    }
    li:before{
        position: absolute;
        font-family: fontawesome;
        left: 0;
        color: $first-color;
    }
}
@function icon($character){
    @return unquote("\"") + unquote(str-insert($character,"\\", 1)) + unquote("\"");
}
@each $name, $content in (check, f00c), (circle, f111), (angle, f105), (angle-double, f105), (angle-arrow, f18e), (caret, f18e) {
    ul.#{$name}UL li:before{
        content: icon($content);
    }
}
/*IMAGE*/
img.pull-left {
    margin-right: 30px;
    margin-bottom: 15px;
}
img.pull-right {
    margin-right: 30px;
    margin-bottom: 30px;
}
.imgCaption{
    position: relative;
    display: inline-block;
    &.pull-left{
        margin-right: 30px;
        margin-bottom: 60px;
    }
    &.pull-right{
        margin-left: 30px;
        margin-bottom: 60px;
    }
    IMG{
        display: inline-block;
        bottom: 0;
    }
}
img.imgDrop {
    @include box-shadow-webkit(0px 0px  15px, rgba(0, 0, 0, 0.3));
}
.imgCaption-content {
    position: absolute;
    width: 90%;
    margin: auto 5%;
    left: 0;
    bottom: -50px;
    right: 0;
    padding: 30px;
    background-color: $six-color;
    color: white !important;
    a{
        color: $third-color;
        &:hover{
            color: $first-color;
        }
    }
    &.imgCaption-content h3, .imgCaption-content p
    {
        margin: 0 0 10px 0;
        padding: 0;
    }
}
/*TAB PANEL*/
.tab-pane {
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $third-color;
}
.nav-tabs{
    > li >a {
        @include border-radius-web-kit($size:0px );
        position: relative;
        border: none;
        padding: 15px 20px;
        font-size: 14px;
        &:hover{
            color: white !important;
            z-index: 5;
        }
        &:after{
            position: absolute;
            content: "";
            width: 100%;
            height: 0px;
            background-color: $gray-color;
            bottom: 0;
            left: 0;
            @include transition-web-kit;
            z-index: -1;
            &:hover{
                height: 100%;
            }
        }
    }
    > li.active > a{
        border: none !important;
        @include transition-web-kit;
        color: white !important;
        z-index: 50;
        position: relative;
        &:hover:after{
            height: 100%;
        }
        &:after  {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            /*background-color: $first-color;*/
            bottom: 0;
            left: 0;
            @include transition-web-kit;
        }
        &:before{
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            /*background: $first-color;*/
            bottom: -5px;
            left: 0;
            right: 0;
            z-index: -1;
            margin: auto;
            @include transition-web-kit;
            @include rotate(45);
        }
    }
}
@each $name, $color in (Blue, $second-color), (Red, $first-color), (Gray, $gray-color), (Orange, $fourth-color), (Black, $six-color){
    .tab#{$name} {
        > li.active > a{
            &:after  {
                background-color:$color;
            }
            &:before  {
                background-color:$color;
            }
        }
    }
}
/*PANEL*/
.panel {
    @include border-radius-web-kit(0px);
    > .panel-heading{
        @include border-radius-web-kit(0px);
    }
}
.panel-group {
    .panel{
        @include border-radius-web-kit(0px);
    }
    .panel-heading{
        padding-top: 15px;
        padding-bottom: 15px;
        A{
            display: inline-block;
            width: 100%;
        }
        A[aria-expanded=true] .fa-angle-right
        {
            @include rotate(90);

            @include transition-web-kit();
        }
        A[aria-expanded=false] .fa-angle-right
        {
            @include transition-web-kit();
        }
    }
}
@each $name, $color in (Blue, $second-color), (Red, $first-color), (Gray, $gray-color), (Orange, $fourth-color),(Black, $six-color){
    .accordion#{$name}{
        .panel-heading{
            background: $color;
            color:white;
            a:hover{
                color: white;
                opacity: 0.6;
            }
        }
    }
}
/*TABLE*/
/*-->Table Global*/
.table{
    > thead > tr > th{

        font-weight: 400;
        padding: 20px !important;
        border-bottom: none;
    }
    tbody tr td{
        font-weight: 400;
        font-size: 14px;
        padding: 20px !important;
    }
}
.table-striped > tbody > tr > td, .table-striped > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th .table-striped > thead > tr > td, .table-striped > thead > tr > th {
    border-color: white;
}
.table-striped > tbody > tr:last-child {
    border-bottom: 1px solid $third-color;
}
.table-striped > tbody > tr:nth-of-type(even) {
    background-color: lighten($third-color, 15);
}
@each $name, $color in (Blue, $second-color), (Red, $first-color), (Gray, $gray-color){
    .table#{$name} thead{
        background: $color;
        color:white;
        border-bottom: none;
    }
}
@each $name, $color in (Blue, rgba(0, 91, 169, 0.2)), (Red, rgba(255, 0, 0, 0.2)), (Gray, rgba(128, 128, 128, 0.2)){
    .table-striped.table#{$name}  > tbody > tr:nth-of-type(odd), .table-hover.table#{$name}  > tbody > tr:hover{
        background-color: $color;
    }
}
/*HEADİNG*/
.doubleLineH {
    position: relative;
    color: black;
    margin-bottom: 30px;
    &:after, &:before{
        position: absolute;
        content: "";
        left:0;
    }
    &:after{
        border-bottom: 2px solid $first-color;
        width: 30%;
        bottom: -15px;
    }
    &:before{
        border-bottom: 1px solid $first-color;
        width: 20%;
        bottom: -20px;
        left: 0;
    }
}
/*-->TOP MENU*/
.top-menu {
    background-color: $first-color;
    padding-top: 12px;
    padding-bottom: 12px;
    a{
        color:white;
        &:hover{
            opacity: 0.7;
        }
    }
    li{
        position: relative;
        padding: 0 10px;
        margin: 0;
        &:after{
            position: absolute;
            content: "";
            height: 100%;
            border-right: 1px solid rgba(255, 255, 255, 0.3);
            margin-left: 10px;
        }
        &:last-child, &:last-child:after{
            padding-right: 0;
            border: none;
            margin-left: 0px;
        }
        &.active a{
            opacity: 0.7;
        }
    }
}
/*-->LOGO*/
.logo {
    padding-top: 20px;
    padding-bottom: 20px;
}
/*-->MAIN MENU*/
.main-menu-container {
    background-color: white;
    font-size: 14px;
    @include box-shadow-webkit(0px 0px 8px 0px, rgba(20, 12, 12, 0.4));
}
.affix{
    width: 100%;
    z-index: 150;
    top: 0px;
}
.main-menu{
    height: 105px;
    a{
        height: 105px;
        display: table-cell;
        vertical-align: middle;
        position: relative;
        transform-origin: center;
        &:before{
            position: absolute;
            content: "";
            width: 0px;
            text-align: center;
            height: 3px;
            background: $first-color;
            bottom: 0;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            @include transition-web-kit()
        }
        &:hover{
            color: $first-color;
        }
        &:hover:before{
            width: 100%;
        }
    }
    li{
        padding: 0;
        margin: 0 10px;
        position: relative;
        .active a{
            color: $first-color;
            &:before{
                width: 100%;
            }
        }
        &:after{
            position: absolute;
            content: "";
            height: 15px;
            border-right: 1px solid rgba(0, 0, 0, 0.3);
            right: -10px;
            top: 50%;
            @include translate(50%,-50%);
        }
        &:last-child,&:last-child:after{
            margin-right: 0;
            border: none;
            right: 0;
        }
    }
}
/*BANNER*/


@each $name, $color in (Blue, $second-color), (Red, $first-color), (Gray, $gray-color){
    .table#{$name} thead{
        background: $color;
        color:white;
        border-bottom: none;
    }
}
/*BANNER GRADIENT*/
.gradient-left {
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 80%);
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 80%);
    background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 80%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 );
    width: 100%;
    height: 100%;
}
.gradient-right {
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 80%);
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 );
    width: 100%;
    height: 100%;
}
.gradient-center {
    background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=1 );
    width: 100%;
    height: 100%;
}
.banner-caption {
    height: calc(100% - 215px);
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    h2{
        font-weight: 300;
        color: white;
    }
    h1{
        color:$first-color
    }
    p{
        color: white;
        font-size: 16px;
        font-weight: 100;
    }
}
/*BANNER QUICK*/
.banner-quick {
    position: relative;
    width: 100%;
    margin: auto;
    padding-bottom: 60px;
}
.bq-card {
    background: rgba(0, 0, 0, 0.6);
    padding: 30px;
    display: inline-block;
    @include transition-web-kit;
    &:hover{
        background: black;
        @include box-shadow-webkit(0px 0px 8px 5px, rgba(0, 0, 0, 0.6));
        h3{
            color:$first-color
        }
    }
    i{
        font-size: 108px;
        color: $first-color;
    }
    h3{
        color: white;
        margin-bottom: 15px !important;
        @include transition-web-kit;
    }
    p{
        color:$gray-color
    }
}
/*HEADER*/
.header {
    height: 400px;
    position: relative;
    background: 0px center no-repeat;
    background-size: cover;
}
.headerColorImg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: left center no-repeat;
}
.header-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    color: white;
    ul.breadcrumb{
        margin: 0;
        background: none;
        padding: 0;
    }
    .breadcrumb > li + li:before{
        color: white;
        content: "\f105";
        font-family: Fontawesome;
    }
    .breadcrumb > li:last-child a{
        font-weight: 700;
    }
    .breadcrumb a{
        color: white;
        &:hover{
            opacity: 0.6;
        }
    }
}

/*DETAIL*/
.detail-container {
    padding-top: 30px;
    padding-left: 5px;
    padding-bottom: 15px;
    p {
        color: $gray-color;
    }
}
/*TITLE*/
.card-title {
    border-bottom: 1px solid $gray-color;
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 15px;
    position: relative;
    font-weight: 300 !important;
    &:before
    {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        background: white;
        border-bottom: 1px solid $gray-color;
        border-right: 1px solid $gray-color;
        bottom: -6px;
        left: 25px;
        @include rotate(45);
    }
}
.title-nav .fa {
    font-size: 24px;
    position: relative;
    top: 3px;
    margin-left: 5px;
}
/*NEW CARD*/
.news-card {
    background: white;
    @include box-shadow-webkit(0px 0px 15px, rgba(0, 0, 0, 0.2));
    .news-card:hover .news-card-img:after {
        background-color: rgba(0, 0, 0, 0.333);
        width: 100%;
        height: 100%;
        position: absolute;
        margin-bottom: 30px;
    }
    &:hover {
        .news-card-img:after{
            background-color: $six-color;
            opacity: 0.5;
        }
        .news-card-img:before {
            top: 40%;
            color: $third-color;
        }
    }

}
.news-card-content {
    padding: 15px;
    color: $gray-color;

}
.news-card-img {
    position: relative;
    cursor: pointer;
    &:hover{
        &:after{
            background-color: $six-color;
            opacity: 0.5;
        }
    }
    &:after {
        position: absolute;
        content: "";
        background-color: transparent;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transform-origin: center center;
        margin: auto;
        @include transition-web-kit;

    }
    &:before {
        position: absolute;
        content: "\e950";
        font-family: atilim;
        font-size: 36px;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: auto;
        text-align: center;
        top: 0%;
        color: transparent;
        @include transition-web-kit;
        z-index: 5;
    }

}
.news-card-caption {
    padding: 10px 15px;
    border-top: 1px solid $third-color;
    color: $gray-color;
}
.news-card-title {
    margin-top: -50px;
    margin-bottom: -20px;
    padding: 15px;
}
/*EVENT*/
.event-card {
    @include box-shadow-webkit(0px 0px 15px, rgba(0, 0, 0, 0.23));
    padding-left: 15px;
    padding-right: 15px;
    color: $gray-color;
}
.event-content {
    padding-top: 15px;
}
.event-content-img {
    padding-bottom: 15px;
    img {
        border: 5px solid white;
        @include box-shadow-webkit(0px 0px 15px, rgba(0, 0, 0, 0.2));
    }
}
.event-content-caption {
    border-bottom: 1px solid $third-color;
    border-top: 1px solid $third-color;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-bottom: 15px;
    i {
        color: $first-color;
        margin-right: 5px;
    }
    span{
        margin-right: 15px;
    }
}
/*-->DATA PICKER*/
.datepicker {
    border-right: 1px solid $third-color;
    padding-right: 15px;
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;
    &:after {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        background: white;
        border-bottom: 1px solid $third-color;
        border-right: 1px solid $third-color;
        top: 28px;
        right: -6px;
        @include rotate(-45)
    }
    table {
        width: 100%;
        font-size: 16px;
    }
}
.ui-datepicker-calendar {
    thead {
        text-transform: uppercase;
    }
}
.ui-widget-header {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 35px;
    margin-bottom: 15px;
}
.ui-datepicker-week-end, .ui-datepicker-week-end a {
    color: $first-color;
    font-weight: 700 !important;
}
a.ui-state-default {
    padding-top: 15px !important;
    padding-bottom: 15px;
    padding: 0;
    display: inline-block;
    font-weight: 300;
}
.ui-datepicker-calendar{
    a {
        display: inline-block;
        text-align: center;
        width: 100%;
        height: 100%;
        padding: 0 !important;
        line-height: 3;
        &:hover {
            background-color: $first-color;
            color: white;
        }
        thead tr th {
            text-align: center;
            height: 50px;
            border-bottom: 1px solid $third-color;
        }
        td {
            background: transparent;
            text-align: center;
        }
    }
}
.ui-datepicker-today {
    background-color: $third-color !important;
}
.ui-datepicker-title {
    color: #000000;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    margin: auto;
    left: 0;
    right: 0;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    .ui-datepicker-year {
        font-weight: 300;
    }
}
@mixin datepicker-nav{
    position: absolute;
    bottom: 5px;
    z-index: 55;
    cursor: pointer;
    font-size: 24px;
}
.ui-datepicker-next {
    @include datepicker-nav;
    right: 10px;
    &:hover{
        right: 0px;
        color: $first-color;
    }
}
.ui-datepicker-prev {
    @include datepicker-nav;
    left: 10px;
    &:hover{
        left:0px;
        color:$first-color;
    }
}
/*NOTICE CARD*/
.notice-card {
    background: $seven-color;
    height: 200px;

}
.notice-card-content-body{
    p{
        padding: 0px;
        margin: 0px;
    }
}
.notice-card-title {
    background: $first-color;
    display: inline-block;
    width: calc(100% - 50px);
    height: calc(100% + 10px);
    padding-left: 25px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    position: relative;
    top:-5px;



    p{
        color: white;
        font-weight: 300;
    }
    a{
        color: $gray-color;
        &:hover{
            color: white;
        }
    }
    &:after{
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        right: -50px;
        top: 0;
        border-style: solid;
        border-width: 105px 0 105px 50px;
        border-color: transparent transparent transparent $first-color;
    }
}
.news-owl .owl-stage-outer{
    margin-top:-15px ;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: -15px;

}
.notice-card-bg{
    background-color: $seven-color;
    padding-right: 15px;
    padding-left: 15px;
}
.notice-card-content {
    color: $third-color;
    padding-top: 15px;
    padding-bottom: 15px;
}
.notice-card-content-title {
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-top: 5px;
    border-bottom: 1px solid #424242;
    a{
        color: $third-color;
        font-size: 15px;
        &:hover{
            opacity: 0.4;
        }
    }
}
/*CIRCLE TAB*/
.circle-tab {
    position: relative;
    height: 600px;
    background: url(../img/circle-bg.svg) center top / cover no-repeat transparent;
    margin-top: 100px;
    .tab-pane{
        padding: 0;
        border: none;
    }
    > ul {
        padding: 0;
        margin: 0;
        > li {
            padding: 0;
            margin: 0;
            list-style: none;
            width: 230px;
            height: 230px;
            @include border-radius-web-kit(50%);
            overflow: hidden;
            padding: 30px;
            background: lighten($third-color, 12%);
            @include transition-web-kit;
            position: absolute;
            z-index: 3;
            &.active{
                background-color: $first-color;
                > a{
                    border-color: darken($first-color, 15%);
                    overflow: hidden;
                    > img{
                        @include scale(1.2);
                    }
                }
            }
            &:hover{
                background-color: $first-color;
                > a{
                    border-color: darken($first-color, 15%);
                    > img{
                        @include scale(1.2);
                    }
                }
            }
            a {
                display: inline-block;
                @include border-radius-web-kit(50%);
                overflow: hidden;
                border: 10px solid $third-color;
                z-index: 55555;
                position: relative;
                > img {
                    @include transition-web-kit(0.9s)
                }
            }
        }
    }


}
.circle-tab-content {
    text-align: center;
    position: absolute;
    width: 70%;
    height: 70%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    p{
        font-size: 18px;
    }
}
.circle-tab ul li {
    &.pos-1 {
        bottom: 15%;
        left: 0;
    }
    &.pos-2 {
        bottom: 60%;
        left: 15%;
    }
    &.pos-3 {
        bottom: 80%;
        left: 0;
        right: 0;
        margin: auto;
    }
    &.pos-4 {
        bottom: 60%;
        right: 15%;
    }
    &.pos-5 {
        bottom: 15%;
        right: 0;
    }
}
/*FULL-BG*/
.full-bg {
    height: auto;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 300px;
    padding-top: 25px;
    padding-bottom: 25px;
    position: relative;
    display: flex;
    justify-items: center;
    flex-direction: row;
    align-items: center;
    background-attachment: fixed;
    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-color: $six-color;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        opacity: 0.9;
    }
}
/*UNIT CARD*/
.unit-card{
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    a.unit-card-link{
        @include position-container;
        z-index: 5;
    }
    &:after{
        @include position-container;
        background: $six-color;
        z-index: 1;
        opacity: 0;
        @include transition-web-kit()
    }
    .unit-card-img{
        @include transition-web-kit();
        position: relative;
        z-index: 1;
    }
    .unit-card-caption{
        position: relative;
        z-index: 2;
        a{
            background: white;
            padding: 25px 15px 25px 15px;
            display: inline-block;
            width: 100%;
        }
    }
    &:hover{
        .unit-card-caption{
            a{
                background: $first-color;
                color: white;
            }
        }
        &:after{
            opacity: 0.6;
        }
        .unit-card-img{
            @include scale(1.1);
        }
    }
}
/*STAFF*/
.staff{
    .staff-item{
        &:first-child{
            display: block;
            margin-left: auto;
            margin-right: auto;
            float: none;
        }
    }
}
.staff-container{
    padding: 30px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-bottom: 30px;
    &:before{
        @include position-container;
        background-color:lighten($third-color, 10);
        left: 0;
        z-index: -1;
        height: 60%;
        @include skewY(20);
        top:-15%;
    }
    .staff-img{
        @include gray-filter();
        @include transition-web-kit();
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &:hover{
            @include gray-filter(0);
            filter:none;
            &:after{
                top: calc(100% + 150px);
                opacity: 0;
            }
        }
        &:after{
            @include position-container;
            background-color:white;
            opacity: 0.5;
            top: -150;
            left: 0;
            @include skewY(20);
            height: 50px;
            @include transition-web-kit(0.5s)
        }

    }
    A{
        color: $first-color;
        &:hover{
            color: $six-color;
        }
    }
    .staff-caption{
        P.title{
            position: relative;
            color: $six-color;
            SPAN{
                background:white;

                padding: 10px;
                position: relative;
                z-index:1;
            }
            &:after{
                position: absolute;
                content: "";
                width: 100%;
                height: 1px;
                left: 0;
                top:10px;
                background-color: $third-color;
                z-index: -1;
            }
        }
    }
}
/*GALLERY CARD*/
.gallery-card{
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    .gallery-card-img{
        position: relative;
        z-index: 1;
        &:after{
            @include position-container;
            background-color: $six-color;
            opacity: 0;
            z-index: 2;
            @include transition-web-kit();
        }
        &:before{
            position: absolute;
            color: white;
            text-align: center;
            width: 100%;
            height: 100%;
            font-size: 48px;
            font-family: FontAwesome;
            content: "\f03e";
            z-index: 3;
            top:35%;
            opacity: 0;
            @include transition-web-kit();


        }
        img{
            @include transition-web-kit();
        }
    }
    .gallery-card-caption{
        position: relative;
        z-index: 2;
        background-color: white;
        @include transition-web-kit();
        p, h3, a, a:after{
            @include transition-web-kit(0.5s);
        }
    }
    &:hover{
        .gallery-card-img{
            &:after, &:before{
                opacity: 0.7;
            }
            img{
                @include scale(1.1);
            }
        }
        .gallery-card-caption{
            background-color: $first-color;
            p, h3, a, a:after{
                color: white !important;
            }

        }
    }
}
/*GALLERY LIST*/
.gallery-list{
    padding-left: 10px;
    padding-right: 10px;
    .gallery-list-item{
        padding: 5px;
    }
    a{
        position: relative;
        overflow: hidden;
        display: inline-block;
        &:before{
            position: absolute;
            color: white;
            text-align: center;
            width: 100%;
            height: 100%;
            font-size: 32px;
            font-family: FontAwesome;
            content: "\f00e";
            z-index: 1;
            opacity: 0;
            top: -30%;
            @include transition-web-kit();
        }
        &:after{
            @include position-container;
            background: $six-color;
            opacity: 0;
            @include transition-web-kit();
        }
        img{
            @include transition-web-kit();
        }
        &:hover{
            &:after{
                opacity: 0.7;
            }
            &:before{
                top:30%;
                opacity: 0.7;
            }
            img{
                @include scale(1.1);
            }
        }
    }
}
/*COUNTER*/
.counter-contaier {
    color: $third-color;
    i {
        font-size: 96px;
        color: $fourth-color;
        margin-right: 20px;
    }
    h1{
        font-size: 42px;
        padding-top: 10px;
    }
}