@import 'icons';
@import "material-dashboard";
@import "helpers";
@import "mixins.scss";
@import "frontend/_helpers";
@import "frontend/_varible";
@import "frontend/_mixin";
@import "./../css/jquery.fancybox.css";
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #ddd;
}

::-webkit-scrollbar-thumb {
  background: $teal-100;
}
html { height: 100%; overflow-y:auto; overflow-x: hidden; }
body { height: 100%;
  overflow: hidden; }
a:hover {
  cursor: pointer;
}
.logo{
  .simple-text {
    i {
      color: rgb(79, 169, 83);
      font-size: 24px;
    }
  }
}
img {
  display: inline-block;
  max-width:100%;
  height: auto;
}
.inline_block {
  display: inline-block;
}
/***********SELECT2**************************/
.select2-container {
  width: 100%!important;
  z-index: 2222;
}
.site-select{
  width: 200px;
  display: inline-block;
  .select2-container{
    width: 200px!important;
  }
}
.select2-container .select2-selection--single,.select2-container--default .select2-selection--single .select2-selection__arrow  {
  height: 36px;
}
/***********SELECT2 END**********************/

/* APP STANDARD TABS */
.nav-tabs {
  &.primary-tab {
    background: $brand-primary;
  }
  &.success-tab {
    background: $brand-success;
  }
  &.info-tab {
    background: $brand-info;
  }

  &.warning-tab {
    background: $brand-warning;
  }

  &.danger-tab {
    background: $brand-danger;
  }
  li{
    display: inline-block;
    float: left;
    padding:0;
    margin: 0;
    a {
      padding: 6px 12px;
      color: #fff;
      display: block;
    }
  }
}
.primary_tabs {
  .nav-tabs {
    background: transparent;
    &.indigo-tab {
      background: $indigo-500;

    }
    li{
      display: inline-block;
      a{
        color: #999!important;
        &:hover{
          color: #000!important;
        }
      }
    }
    li.active {
      a{
        color: #000!important;
        background-color: rgba(0, 0, 0, 0.2);

      }
    }
  }
}
.form-control, .form-group .form-control {
  /*border-bottom: 1px solid #ccc;*/

}
/* APP STANDARD TABS END*/

.card{
  &.card-primary{
    .card-header {
      background-color: $brand-primary
    }
  }
  &.card-teal-1{
    .card-header {
      background-color: $teal-300
    }
  }

}

.modal-lg {
  width: 90vw;
  margin-top: 5px!important;
  .modal-content{
    .modal-body{
      height: 85vH;
      padding: 15px;
      overflow: hidden;
      .card{
        height: 95%;
        .card-content{
          height:90%;
        }}
    }
  }
}
.mCSB_inside > .mCSB_container {
  margin-right: 15px!important;
}
.content-input {
  border: 1px solid $grey-300;
  margin-bottom: 30px;
  .input-actions {
    position: absolute;
    top: -20px;
    right: 15px;
    visibility: hidden;
    opacity: 0;
    @include transition;
    >ul {
      background-color: $teal-600;
      margin:0;

      li {
        position: relative;
        >ul {
          display: none;
          position: absolute;
          left:0;
          top: 30px;
          margin: 0;
          padding: 0;
          white-space: nowrap;
          z-index: 5;

        }
        &:hover {
          >ul {
            background-color: lighten($deep-purple-400, 10);
            display: block;
            list-style: none;
            >li:hover {
              @include transition;
              background-color: $deep-purple-600;
            }
          }
        }
        a{
          color: #fff;
          padding:10px;
          display: block;
        }
      }
    }
  }
  &:hover {
    border-color: $brand-primary;
    .input-actions {
      visibility: visible;
      opacity: 1;
    }
  }


}
.content-preview {
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  /*Nav tabs*/


}
$colors: ($atilim-dark-gray-color, dark-gray), ($brand-primary, primary), ($brand-success,success), ($atilim-red-color,red);
.atilim-tabs {

  .tab-pane {
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $third-color;
  }
  .nav-tabs{
    padding:0;
    border-bottom: 1px solid #ddd;
    background-color: transparent;
    > li >a {
      @include border-radius-web-kit($size:0px );
      position: relative;
      border: none;
      padding: 15px 20px;
      font-size: 14px;
      color: $six-color!important;
      &:hover{
        color: white !important;
        z-index: 5;
        background-color: #ccc;
      }
      &:after{
        position: absolute;
        content: "";
        width: 100%;
        height: 0px;
        background-color: $gray-color;
        bottom: 0;
        left: 0;
        @include transition-web-kit;
        z-index: -1;
        &:hover{
          height: 100%;
        }
      }
    }
    > li.active > a{
      border: none !important;
      @include transition-web-kit;
      color: white !important;
      z-index: 50;
      position: relative;
      &:hover:after{
        height: 100%;
      }
      &:after  {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-color: $first-color;
        bottom: 0;
        left: 0;
        @include transition-web-kit;
      }
      &:before{
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        background: $first-color;
        bottom: -5px;
        left: 0;
        right: 0;
        z-index: -1;
        margin: auto;
        @include transition-web-kit;
        @include rotate(45);
      }
    }
  }
  @each $name, $color in (Blue, $second-color), (Red, $first-color), (Gray, $gray-color), (Orange, $fourth-color), (Black, $six-color){
    .tab#{$name} {
      > li.active > a{
        &:after  {
          background-color:$color!important;
        }
        &:before  {
          background-color:$color!important;
        }
      }
    }
  }

}



.color-preview {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-right:15px;
  opacity: .5;
  &.active {
    box-shadow: rgba(0, 0, 0, 0.62) 0px 0px 5px 1px;
    opacity:1;
  }

}
.file-preview-frame {
  background: #fff;
  .file-drag-handle:hover {
    cursor: grab;
  }
}
.cms-thumb {
  max-width: 200px;
  display: inline-block;
  min-width: 100px;
  min-height: 100px;
  .file-preview-frame {
    border: 2px solid transparent;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    &:hover {
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)!important;
    }
    .kv-file-content{
      height: auto;
      position: relative;
      .file_action{
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        opacity: 0;
        visibility: hidden;
        @include transition(.5s,all);
        @include hide();
        z-index: auto;
      }
      &:hover {
        .file_action {
          @include show();
        }
      }
    }
  }
  &.selected {

    .file-preview-frame {
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)!important;
      border-color: $indigo-500;



    }

  }
}
.selected {
  img{
    opacity: .5;
  }
  .card{
    background-color: $light-blue-300;
  }
  .gallery-preview-item {


    a:hover{

    }
  }
}

.card.file_thumb {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  &:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)!important;
  }
  .file-drag-handle{
    cursor: grab;
  }
  .file-preview-thumb {
    padding:5px;

  }
  .file-props{
    .form-group{
      padding-bottom: 5px;
      margin: 10px 0 0 0;
    }
  }
  text-align: left;
  .card-content{
    padding:10px;

    h5{
      margin: 5px 0;
      font-size:17px;
      font-weight: 400;
    }
  }
  .card-footer {
    padding: 10px 10px 0;
    margin: 0 0 10px;

  }

}
.drag-handle_ {
  cursor: move;
}
.media-browser-wrapper {
  border-radius: 3px;
  .nav.nav-tabs {
    padding:0;
    >li {
      >a {
        padding: 12px;
      }
    }
  }
  border: 1px solid $brand-success;
  background: #eeeeee;
  .tab-content {
    min-height: 500px;
    display: table;
    width: 100%;
    .tab-pane{
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      overflow: hidden;
    }
  }
}
.media-browser-thumb {
  width: 230px;
  float: left;
  padding:10px;
}

.bootstrap-datetimepicker-widget{
  z-index: 999;
  background: #fff;
  visibility: visible!important;
  opacity: 1!important;
}
/* Table Builder */
.table_builder{
  ul.nav-tabs{
    padding:0;
    margin:0;
    >li >a {
      padding: 12px;
    }
  }
}
/* Table Builder End */

/* Table Editor */


.table_editor {
  .inline-edit-wrapper {
    position: absolute;
    bottom:0;
    left: 50%;
    display: block;
    text-align: center;
    z-index: 5;

    a{
      @include hide();
      display: inline-block;
    }
  }
  min-height: 80px;
  padding: 50px 15px 20px;
  .table-actions {
    &.actions-head{
      top: 20px;
      right: 20px;
    }
    &.actions-row{
      bottom: -10px;
      right: 20px;
    }
    position: absolute;
    @include transition(.5s,all);
    @include hide();
    z-index: 10;

  }
  &:hover {
    .inline-edit-wrapper {
      a{
        @include show();
      }
    }
    .table-actions {
      @include show()
    }
  }
}
/* Table Editor */

/*List Editor */
.list_editor {
  position:relative;
  .list-actions {
    position: absolute;
    bottom:-15px;
    width:100%;
    display: block;
    text-align: center;
    z-index: 5;
    a{

      @include hide();
    }
  }
  &:hover {
    .list-actions a {
      @include show();
      display: inline-block;
    }
  }
}

.btn-group {
  .selected {
    z-index: 2;
    -webkit-box-shadow: 0 14px 26px -12px rgba(92, 107, 192, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(92, 107, 192, 0.2);
    -moz-box-shadow: 0 14px 26px -12px rgba(92, 107, 192, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(92, 107, 192, 0.2);
    box-shadow: 0 14px 26px -12px rgba(92, 107, 192, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(92, 107, 192, 0.2);
  }
}

.tab_left {
  .nav.nav-tabs {
    border-radius: 0;
    padding:0;
    li {
      display:block;
      float: none!important;
    }
  }
}
.ancestors.breadcrumb {
  li{
    cursor:pointer;
    &:last-child {
      cursor:default;
    }
  };
}
.sort-loader{
  position: absolute;
  top:0;
  left:0;
  bottom: 0;
  right: 0;
  z-index: 15;
  background-color: #fff;
  .v-spinner{
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
.panel-group.widget-panel .panel-heading {
  .panel-title {
    width:  85%;
    display: inline-block;

  }

  a {
    display: inline-block;
    width: 100%;
  }
  .btn-group{

    width:14%;
    display: inline-block;
  }
}
/*List Editor End*/
/* ICON selector */
.row.widget-drag-handle {
  .widget-actions{
    display:none;
    position: absolute;
    @include transition(.3s,all);
    top: 10px;
    right: 15px;
    z-index: 20;
    a {
      position: relative;
    }
  }
  border: 1px solid transparent;
  &:hover{
    .widget-actions{
      display:block;

    }
  }
  &.in_drag:hover {
    border:1px solid red;
    cursor: grab;
  }
}
.icon-selector{
  max-height: 200px;
  overflow:hidden;
  position: relative;
  width: 100%;
  ul {
    list-style: none;
    li{
      @include transition(.5s,all);
      float: left;
      padding:10px;
      width: 50px;
      border:1px solid transparent;
      &.active,&:hover {
        border: 1px solid $brand-primary;
      }
      a{
        i{
          font-size: 20px;
        }
      }
    }
  }
}
/* ICON selector End*/
.panel-group.entry-select-comp{
  > .panel{
    > .panel-collapse{
      > .panel-body{
        padding: 15px 5px 15px 15px;
        height: 280px;
        overflow: hidden;
        .panel-body{
          padding:0;
        }
      }
    }

  }
}
.entry-list {
  list-style: none;
}
/* Menu Builder List Perview */


.menu-preview {
  ul {
    padding: 0;
    list-style: none;
    li {
      ul {
        padding: 0 0 0 15px;
      }
      display: block;
      .menu-node-wrapper {
        .menu-node-header {
          background-color: #fafafa;
          border: 1px solid #ddd;
          a {
            padding: 15px;
            color: black;

            display: inline-block;
          }



        }

        .menu-node-content {

        }
      }
    }
  }
  .sortable-tree{
    * {

      position: relative;

    }
    .content {
      height: unset!important;
    }
    li{
      padding-left: 15px!important;
      &::before, &::after{
        border:none;
        display: none;
      }
      &.parent-li {
        &:nth-last-child(2) {
          &::before {
            border:none;
            display: none;
          }
        }
      }


    }
  }

}

.h-100 {
  height: 100%;
}
.o-hidden {
  overflow: hidden;
}
@import 'frontend';