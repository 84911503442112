@mixin transition-web-kit ($time:0.3s){
      -o-transition: $time;
      -ms-transition: $time;
      -moz-transition: $time;
      -webkit-transition: $time;
      transition: $time;
}
@mixin border-radius-web-kit($size:100px){
      border-radius: $size;
      -moz-border-radius: $size;
      -webkit-border-radius: $size;
      -khtml-border-radius: $size;
}
// generic transform
@mixin transform($transforms) {
      -moz-transform: $transforms;
      -o-transform: $transforms;
      -ms-transform: $transforms;
      -webkit-transform: $transforms;
      transform: $transforms;
}

@mixin translate($translate) {
      -moz-transform: translate($translate);
      -o-transform: translate($translate);
      -ms-transform: translate($translate);
      -webkit-transform: translate($translate);
      transform: translate($translate);
}
@mixin rotate ($deg) {
      @include transform(rotate(#{$deg}deg));
}
@mixin skewX ($skewX) {
      @include transform(skewX(#{$skewX}deg));
}

@mixin skewY ($skewY) {
      @include transform(skewY(#{$skewY}deg));
}
@mixin skew ($skewX, $skewY) {
      @include transform(skew(#{$skewX}deg, #{$skewY}deg));
}
@mixin translate ($x, $y) {
      @include transform(translate(#{$x, $y}));
}
@mixin scale ($x) {
      @include transform(scale(#{$x}));
}
@mixin box-shadow-webkit($shadow-settings:0px 0px 5px 0px, $shadow-color:rgba(0, 0, 0, 0.4)){
      -moz-box-shadow: $shadow-settings $shadow-color;
      -webkit-box-shadow: $shadow-settings $shadow-color;
      box-shadow:$shadow-settings $shadow-color;
}
@mixin gray-filter($varible:100%){

}
@mixin position-container{
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
}