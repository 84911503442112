$font-family:'Roboto Slab', serif;
/*COLOR*/
$text-color:#363636;
$first-color:#ED1C24;
$second-color:#1F3D7C;
$third-color:#CCC;
$fourth-color:#FFB400;
$six-color:#262626;
$seven-color:#292C37;
$gray-color:#808080;

/*FONT SIZE*/
$lg-font-size:18px;
$md-font-size:14px;
$sm-font-size:12px;
$xs-font-size:10px;
